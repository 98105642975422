<template>
  <PrintPage v-if="project" :pageNumber="pageNumber" :pageCount="pageCount" :showPageNumber="false">
    <template v-slot:header>
      <slot>
        <div class="header">
          <div class="logo">
            <img src="../../assets/qcells_logo.png" alt="QCells" />
          </div>
        </div>
      </slot>
    </template>

    <div class="content-header">
      <div class="content-header-flex">
        <div class="recipient">
          <h1>SSP Dach UG</h1>
          <div>Münchner Str. 12</div>
          <div>D-85375 Neufahrn</div>
        </div>

        <div class="document-meta-grid">
          <div>Datum</div>
          <div>{{ $date() }}</div>

          <div>Ansprechpartner/in</div>
          <div>Ijas Zubi</div>

          <div>Projekt Supporthotline</div>
          <div>+49 01234 567890</div>

          <div>E-Mail</div>
          <div>i.zubi@q-cells.com</div>

          <div>Projekt-Nr. VLINK</div>
          <div>{{ project.employerProjectNumber }}</div>
        </div>
      </div>

      <div class="intro">
        Sehr geehrte Damen und Herren der Fa. SSP Dach UG,<br />

        hiermit beauftragen wir Sie mit der Umsetzung des Projekts
        <strong>#{{ project.employerProjectNumber }}</strong>
        auf Basis der bestehenden Rahmenvereinbarung und der mit Ihnen vereinbarten Konditionen:
      </div>
    </div>

    <div class="content-main">
      <div>
        <div class="system-overview-grid">
          <div>Fachpartnerbetrieb</div>
          <div>SSP Dach UG</div>

          <div>Paket</div>
          <div>{{ project.solarPlant.qcellsKit }}</div>

          <div>PV Anlage</div>
          <div v-if="project.solarPlant.battery">Mit Speicher</div>
          <div v-else>Ohne Speicher</div>

          <div>Anlagengröße</div>
          <div>{{ formatSolarPanelsOverallPower(project) }} kWp</div>

          <div>Anzahl Module</div>
          <div>{{ project.solarPlant.solarPanelCount }}</div>

          <div>Wechselrichter</div>
          <div>{{ project.solarPlant.inverter1.type }}</div>

          <div>Speicher</div>
          <div v-if="project.solarPlant.battery">{{ project.solarPlant.battery.type }}</div>
          <div v-else>-</div>
        </div>
      </div>
      <div class="offer-positions">
        <div class="heading bg-green">
          {{ project.solarPlant.qcellsKit }} - {{ formatSolarPanelsOverallPower(project) }} kWp
        </div>

        <div class="positions-grid">
          <div>kleines Material</div>
          <div>{{ $n(priceSmallMaterial, 'currency_rounded') }}</div>
          <div>Vor-Ort-Termin</div>
          <div>{{ $n(priceOnSiteAppointment, 'currency_rounded') }}</div>
          <div>DC Seite</div>
          <div>{{ $n(priceDc, 'currency_rounded') }}</div>
          <div>AC Seite / Pauschal</div>
          <div>{{ $n(priceAc, 'currency_rounded') }}</div>
          <div>Inbetriebnahme / Pauschal</div>
          <div>{{ $n(priceFirstTimeOperation, 'currency_rounded') }}</div>
          <div v-if="project.solarPlant.battery">Speichersystem / Pauschal</div>
          <div v-if="project.solarPlant.battery">{{ $n(priceBattery, 'currency_rounded') }}</div>
        </div>
        <div class="positions-grid sum mb">
          <div>Paket</div>
          <div>{{ $n(pricePackageSummary, 'currency_rounded') }}</div>
        </div>

        <div class="heading bg-green">
          Zusatzoptionen
        </div>

        <div class="positions-grid">
          <template v-for="(item, index) in projectItems">
            <div :key="index + '_description'"><span v-html="item.item.description"></span></div>
            <div :key="index + '_price'">
              {{ $n(item.count * item.item.price, 'currency_rounded') }}
            </div>
          </template>
        </div>

        <div class="positions-grid sum mb">
          <div>Zusatzoptionen</div>
          <div>{{ $n(priceOptionsSummary, 'currency_rounded') }}</div>
        </div>
        <div class="positions-grid sum bg-green">
          <div>{{ $t('total_order_net') }}</div>
          <div>{{ $n(priceSummary, 'currency_rounded') }}</div>
        </div>
        <div class="positions-grid sum bg-green last">
          <div>Gesamtauftrag (brutto)</div>
          <div>{{ $n(priceGrossSummary, 'currency_rounded') }}</div>
        </div>
      </div>
    </div>
    <footer>
      <img src="../../assets/qcells_footer.png" />
    </footer>
  </PrintPage>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PrintPage from '@/components/Print/PrintPage';
import { ExtendedProject } from '@/helpers/projects';
import { formatSolarPanelsOverallPower, solarPlantOverallPower } from '@/helpers/projects';

export default {
  components: {
    PrintPage,
  },
  props: {
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 1 },
    projectNumber: {
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getProjectByNumber']),
    project() {
      const project = this.getProjectByNumber(this.number);
      if (project) {
        new ExtendedProject(project);
      }
      return project;
    },

    projectItems() {
      console.log(this.project.items.filter((item) => item.item.type === 'QCELLS'));
      return this.project.items.filter((item) => item.item.type === 'QCELLS');
    },

    /** Prices */

    priceSmallMaterial() {
      const pricePerKw = 40;
      return Math.ceil(this.solarPlantOverallPower(this.project) * pricePerKw);
    },
    priceOnSiteAppointment() {
      return 200;
    },
    priceDc() {
      const pricePerModule = 90;
      return this.project.solarPlant.solarPanelCount * pricePerModule;
    },
    priceAc() {
      return 800;
    },
    priceFirstTimeOperation() {
      return 600;
    },
    priceBattery() {
      return this.project.solarPlant.battery ? 750 : 0;
    },
    pricePackageSummary() {
      return (
        this.priceSmallMaterial +
        this.priceOnSiteAppointment +
        this.priceDc +
        this.priceAc +
        this.priceFirstTimeOperation +
        this.priceBattery
      );
    },
    priceOptionsSummary() {
      if (!this.project.items) return 0;
      const totalCost = this.projectItems.reduce((acc, curr) => {
        return acc + curr.count * curr.item.price;
      }, 0);

      return totalCost;
    },
    priceSummary() {
      return this.pricePackageSummary + this.priceOptionsSummary;
    },
    priceGrossSummary() {
      return this.priceSummary * 1.19;
    },
  },
  methods: {
    ...mapActions(['fetchProjectByNumber']),
    formatSolarPanelsOverallPower,
    solarPlantOverallPower,
  },
  async mounted() {
    this.number = this.projectNumber;
    await this.fetchProjectByNumber(this.number);
  },
};
</script>

<style src="./ProjectQcellsOfferPrint.scss" lang="scss" />
